<template>
    <WidgetWrapper :widget="widget">
        <template slot="actions">
            <a-button
                type="ui" 
                ghost 
                flaticon
                shape="circle"
                icon="fi-rr-plus"
                @click="addTask()" />
        </template>
        <div class="h-full">
            <UniversalTable 
                :model="model"
                :pageName="pageName"
                :tableType="tableType"
                autoHeight
                :openHandler="openTask"
                :endpoint="endpoint"
                main
                :taskType="task_type"
                :takeTask="takeTask"
                showChildren
                :hash="false" />
        </div>
    </WidgetWrapper>
</template>

<script>
import UniversalTable from '@/components/TableWidgets/UniversalTable'
import { mapActions } from 'vuex'
import eventBus from '@/utils/eventBus'
import WidgetWrapper from '../WidgetWrapper.vue'
export default {
    components: {
        UniversalTable,
        WidgetWrapper
    },
    props: {
        widget: {
            type: Object,
            required: true
        }
    },
    computed: {
        endpoint() {
            return `/tasks/task/list/?task_type=${this.task_type}`
        },
        pageName() {
            return (this.widget.page_name || this.widget.id) || this.widget.widget.id
        }
    },
    data() {
        return {
            listLoading: false,
            taskList: [],
            page: 1,
            count: 0,
            task_type: 'task',
            model: 'tasks.TaskModel',
            tableType: 'tasks'
        }
    },
    async created() {
        await this.getTableInfo({ 
            page_name: this.pageName, 
            model: this.model,
            table_type: this.tableType  
        })

        this.getTaskList()
    },
    methods: {
        ...mapActions({
            getTableInfo: 'table/getTableInfo'
        }),
        addTask() {
            this.$store.dispatch('task/sidebarOpen', {
                task_type: this.task_type,
                create_handler: this.pageName
            })
        },
        async getTaskList() {
            try {
                this.listLoading = true
                const { data } = await this.$http.get('/tasks/task/list/', {
                    params: {
                        page_name: this.pageName,
                        task_type: this.task_type,
                        page: this.page,
                        page_size: 15
                    }
                })
                if(data) {
                    this.taskList = data.results
                    this.count = data.count
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.listLoading = false
            }
        },
        changePage(page) {
            this.page = page
            this.getTaskList()
        },
        openTask() {

        },
        takeTask() {

        },
        reloadTask() {
            this.page = 1
            this.count = 0
            this.taskList = []
            this.getTaskList()
        }
    },
    mounted() {
        eventBus.$on(`update_filter_${this.model}_${this.pageName}`, () => {
            this.reloadTask()
        })
        eventBus.$on(`TASK_CREATED_${this.task_type}_${this.pageName}`, () => {
            this.reloadTask()
        })
    },
    beforeDestroy() {
        eventBus.$off(`update_filter_${this.model}_${this.pageName}`)
        eventBus.$off(`TASK_CREATED_${this.task_type}_${this.pageName}`)
    }
}
</script>